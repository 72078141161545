<template>
<div>
  <v-expand-transition>
    <v-card v-show="showFilters" class="mb-6">
        <v-form>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
                <div style="display: flex; flex-direction: row;">
                  <v-autocomplete
                      v-model="shipmentsFilter.customerCode"
                      :items="customers"
                      :label="$t('documentCustomer')"
                      multiple
                      chips
                      deletable-chips
                      hide-details="auto"
                      dense
                      clearable
                      autocomplete="nope"
                      outlined
                      v-if="customerSearchType === 'list'"
                  ></v-autocomplete>
                  <v-text-field
                      v-model="shipmentsFilter.customerSearchString"
                      outlined
                      clearable
                      dense
                      autocomplete="nope"
                      hide-details="auto"
                      :label="$t('documentCustomer')"
                      @keyup.enter="applyDdtsFilters"
                      v-if="customerSearchType === 'string'">
                  </v-text-field>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          color="primary"
                          class="pa-0"
                          style="min-width: initial;"
                          v-bind="attrs"
                          v-on="on"
                          @click="switchCustomerSearchType">
                        <v-icon x-small v-if="customerSearchType === 'list'">mdi-format-list-checks</v-icon>
                        <v-icon x-small v-if="customerSearchType === 'string'">mdi-format-letter-starts-with</v-icon>
                      </v-btn>
                    </template>
                    <span v-if="customerSearchType === 'list'">{{ $t('switchToParam', {param:$t('stringSearch')}) }}</span>
                    <span v-if="customerSearchType === 'string'">{{ $t('switchToParam', {param:$t('listSearch')}) }}</span>
                  </v-tooltip>
                </div>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                    v-model="shipmentsFilter.recipientSearchString"
                    outlined
                    clearable
                    dense
                    autocomplete="nope"
                    hide-details="auto"
                    :label="$t('recipient')"
                    @keyup.enter="applyDdtsFilters">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field dense outlined hide-details="auto" v-model="shipmentsFilter.year" :label="$t('year')" required type="number"
                  @keyup.enter="applyDdtsFilters"></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field dense outlined hide-details="auto" v-model="shipmentsFilter.docDate" :label="$t('documentDate')" required type="text"
                  @keyup.enter="applyDdtsFilters"></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-autocomplete dense outlined clearable chips multiple hide-details="auto"
                                v-model="shipmentsFilter.documentCategory" :items="dropdownDocumentCategories" :label="$t('docType')" required></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="1">
                <v-text-field dense outlined hide-details="auto" clearable v-model="shipmentsFilter.code" :label="$t('numero')" required type="text"
                  @keyup.enter="applyDdtsFilters"></v-text-field>
              </v-col>
              <v-col cols="12" sm="1">
                <v-text-field dense outlined hide-details="auto" clearable v-model="shipmentsFilter.bis" :label="$t('bis')" required type="text"
                  @keyup.enter="applyDdtsFilters"></v-text-field>
              </v-col>
              <v-col cols="12" sm="1" offset="10">
                <v-btn class="float-right" @click="clearDdtsFilters" v-t="'clear'"></v-btn>
              </v-col>
              <v-col cols="12" sm="1">
                <v-btn block @click="applyDdtsFilters" v-t="'apply'"></v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
      </v-card>
  </v-expand-transition>
  <div v-if="ddtView">
    <v-card class="px-0 pt-0">
      <v-card-text class="px-0 py-0">
        <v-row>
          <v-col class="pt-1">
            <v-toolbar flat>
              <v-toolbar-title>TNT</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-btn :color="ddtView? 'primary' : 'white'" dark class="mb-2 mx-1" @click="switchToDdtView()" v-t="'documentsToPrint'"></v-btn>
              <v-btn :color="shipmentView? 'success' : 'white'" class="mb-2 mx-1" @click="switchToShipmentView()" v-t="'printedShipments'"></v-btn>
              <v-spacer></v-spacer>
              <v-btn color="info" dark class="mb-2 mr-2" @click="showFilters = !showFilters">
                <v-icon>mdi-filter</v-icon>
              </v-btn>
            </v-toolbar>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="text-sm-body-1 ps-4">
              <i v-t="'selectOneOrMoreTransportDocuments'"></i>
            </span>
          </v-col>
          <v-col cols="12">
            <span class="ps-4" v-t="'alreadyHandledDocuments'">
            </span>
            <v-chip-group class="ps-4">
              <v-chip class="striped_processed" v-t="'printed'" @click="filterByStatus(1)"></v-chip>

              <v-chip class="striped_wip" v-t="'generatedWithErrors'" @click="filterByStatus(3)"></v-chip>

              <v-chip class="striped_canceled" v-t="'canceled'" @click="filterByStatus(2)"></v-chip>

              <v-chip class="striped_fulfilled" v-t="'fulfilled'" @click="filterByStatus(4)"></v-chip>

              <v-chip class="striped_fulfilledWithOther" v-t="'fulfilledWithOther'" @click="filterByStatus(5)"></v-chip>

              <v-chip v-t="'allShipments'" @click="filterByStatus(0)"></v-chip>
            </v-chip-group>
          </v-col>
        </v-row>

        <v-data-table ref="ddtTable"
          :headers="ddtHeaders"
          :items="ddts" item-key="id"
          class="elevation-1 mt-4" :loading="!ddts" :loading-text="$t('loading')"
          :options.sync="ddtOptions"
          :page.sync="ddtPage"
          :items-per-page.sync="ddtItemsPerPage"
          :footer-props="ddtFooterProps"
          >
          <template v-slot:header.select="{ }">
            <v-checkbox
                v-model="selectAll"
                @change="toggleSelectAll"
                hide-details
                style="margin: 0; padding: 0;"
            ></v-checkbox>
          </template>
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.id" :class="getStatusBackground(item)">
                <td v-if="getStatus(item) == 0">
                  <v-checkbox v-model="selectedDocs" hide-details style="margin:0; padding:0;"
                    :value="item.id"></v-checkbox>
                </td>
                <td v-else></td>
                <td><span class="bold">{{ item.doctext }}</span></td>
                <td>{{ item.customertext }}</td>
                <td>{{ item.recipienttext }}</td>
                <td>{{ moment(item.documentDate).format("DD-MM-YYYY") }}</td>
                <td>
                  <div class="float-right"><MFButtonPill :collection="getDocumentActionCollection(item)"></MFButtonPill></div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <v-speed-dial bottom right fixed v-if="!!selectedDocs.length">
            <template v-slot:activator>
                <v-btn color="info" elevation="24" fab x-large @click="printShipment()">
                    <v-icon>mdi-printer</v-icon>
                </v-btn>
            </template>
        </v-speed-dial>
      </v-card-text>
    </v-card>
  </div>
  <div v-else>
    <v-card class="px-0 pt-0">
      <v-card-text class="px-0 py-0">
        <v-row>
          <v-col class="pt-1">
            <v-toolbar flat>
              <v-toolbar-title>TNT</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-btn :color="ddtView? 'primary' : 'white'" class="mb-2 mx-1" @click="switchToDdtView()" v-t="'documentsToPrint'"></v-btn>
              <v-btn :color="shipmentView? 'success' : 'white'" class="mb-2 mx-1" @click="switchToShipmentView()" v-t="'printedShipments'"></v-btn>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mb-2 mr-2" color="info darker-2" dark v-bind="attrs" v-on="on"><v-icon>mdi-clipboard-list</v-icon></v-btn>
                </template>
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2" v-t="'printBordereau'"></v-card-title>
                  <v-card-text>
                    <v-text-field dense outlined hide-details="auto" type="text"
                        v-model="bordereauDate"
                        @keyup.enter="downloadBordereau"
                        :label="$t('date')"
                    ></v-text-field>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="downloadBordereau()" v-t="'download'"></v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-btn color="info" dark class="mb-2 mr-2" @click="showFilters = !showFilters">
                <v-icon>mdi-filter</v-icon>
              </v-btn>
            </v-toolbar>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="text-sm-body-1 ps-4">
              <i v-t="'cancelOrReprint'"></i>
            </span>
          </v-col>
          <v-col cols="10">
            <span class="ps-4" v-t="'shipmentStatuses'"></span>
            <v-chip-group class="ps-4">
              <v-chip class="processed" text-color="white" v-t="'printed'" @click="filterByStatus(1)"></v-chip>

              <v-chip class="wip" text-color="white" v-t="'generatedWithErrors'" @click="filterByStatus(3)"></v-chip>

              <v-chip class="canceled" text-color="white" v-t="'canceled'" @click="filterByStatus(2)"></v-chip>

              <v-chip class="fulfilled" text-color="white" v-t="'fulfilled'" @click="filterByStatus(4)"></v-chip>

              <v-chip class="fulfilledWithOther" text-color="white" v-t="'fulfilledWithOther'" @click="filterByStatus(5)"></v-chip>

              <v-chip v-t="'allShipments'" @click="filterByStatus(0)"></v-chip>
            </v-chip-group>
          </v-col>
          <v-col cols="2">
            <v-text-field dense outlined hide-details="auto" v-model="shipmentsFilter.shipmentDate" :label="$t('shipmentDate')" type="text"
              @keyup.enter="applyDdtsFilters" class="me-5 mt-4"></v-text-field>
          </v-col>
        </v-row>
        <v-data-table ref="shipmentTable"
          :headers="shipmentHeaders" :items="shipments" item-key="id" class="elevation-1 mt-4"
          :loading="!shipments" :loading-text="$t('loading')"
          :options.sync="shipmentOptions"
          :page.sync="shipmentPage"
          :items-per-page.sync="shipmentItemsPerPage"
          :footer-props="shipmentFooterProps"
          >
          <template v-slot:item.shipmentStatus="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-chip v-bind="attrs" v-on="on" :color="getShipmentStatusInfo(item.shipmentStatus ?? 0).color" text-color="white" style="font-weight: 600; font-size: 0.875rem;">
                  <v-icon class="roundChip" left>{{ getShipmentStatusInfo(item.shipmentStatus ?? 0).icon }}</v-icon>
                </v-chip>
              </template>
              <span>{{ getShipmentStatusInfo(item.shipmentStatus ?? 0).label }}</span>
            </v-tooltip>
            <span class="bold ml-2">{{ item.consignmentNo ?? "" }}</span>
          </template>
          <template v-slot:item.docstext="{ item }">
            <span class="bold">{{ item.docstext}}</span>
          </template>
          <template v-slot:item.shipmentDate="{ item }">
            <span>{{ moment(item.shipmentDate).format("DD-MM-YYYY") }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="float-right"><MFButtonPill :collection="getShipmentActionCollection(item)"></MFButtonPill></div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</div>
</template>

<script>
import { get, sync, call } from "vuex-pathify";
import MFButtonPill from "@/components/utility/MFButtonPill.vue";
import moment from "moment-timezone";

export default {
    name: "TntLabels",
    components: {
      MFButtonPill
    },
    data: () => ({
      dialog: false,
      bordereauDate: null,
      customerSearchType: 'list',
      recipientSearchType: 'list',
      searchDocuments: "",
      searchShipments: "",
      ddts: [],
      shipments: [],
      selectAll: false,
      selectedDocs: [],
      ddtOptions: {},
      shipmentOptions: {},
      ddtPage: 1,
      shipmentPage: 1,
      ddtItemsPerPage: 10,
      shipmentItemsPerPage: 10,
      ddtFooterProps: { showFirstLastPage: true, firstIcon: 'mdi-page-first', lastIcon: 'mdi-page-last', prevIcon: 'mdi-chevron-left', nextIcon: 'mdi-chevron-right'},
      shipmentFooterProps: { showFirstLastPage: true, firstIcon: 'mdi-page-first', lastIcon: 'mdi-page-last', prevIcon: 'mdi-chevron-left', nextIcon: 'mdi-chevron-right'},
    }),
    computed: {
      dropdownDocumentCategories: get("tnt/dropdownDocumentCategories"),
      ddtView: get("tnt/ddtView"),
      shipmentView: get("tnt/shipmentView"),
      documents: sync("tnt/documents"),
      shipmentsFilter: sync("tnt/shipmentsFilter"),
      showFilters: sync("tnt/showFilters"),
      shipment:sync("tnt/shipment"),
      customers: get('customers/dropdownAllBulkCustomersCode'),
      ddtHeaders(){
        return [
          { text: "", sortable: false, value: 'select', width: 100 },
          { text: this.$t('shippingDoc'), align: 'left', sortable: true, value: 'doctext', width: 200 },
          { text: this.$t('documentCustomer'), align: 'left', sortable: true, value: 'customertext' },
          { text: this.$t('recipient'), align: 'left', sortable: true, value: 'recipienttext' },
          { text: this.$t('documentDate'), align: 'left', sortable: true, value: 'documentDate' },
          { text: this.$t('actions'), align: 'center', sortable: false, value: 'actions', width: 100 },
        ]
      },
      shipmentHeaders(){
        return [
          { text: '', align: 'left', sortable: true, value: 'shipmentStatus', width: 200 },
          //{ text: this.$t('shippingDoc'), align: 'left', sortable: false, value: 'docstext', width: 200 },
          { text: this.$t('documentCustomer'), align: 'left', sortable: true, value: 'customertext' },
          { text: this.$t('recipient'), align: 'left', sortable: true, value: 'recipienttext' },
          { text: this.$t('shipmentDate'), align: 'left', sortable: true, value: 'shipmentDate' },
          { text: this.$t('actions'), align: 'center', sortable: false, value: 'actions', width: 275 },
        ]
      },
    },
    watch: {
      selectedDocs: {
        handler(newVal) {
          this.selectAll = newVal.length === this.ddts.length;
        },
        deep: true,
      },
    },
    created() {
      this.initialize();
      this.clearedBordereauDate();
    },
    methods: {
      moment,
      clearedBordereauDate() {
        let todayDate = this.$moment();
        this.bordereauDate = `${!!todayDate ? todayDate.format("DD/MM/YYYY") : ``}`;
      },
      toggleSelectAll() {
        if (this.selectAll) {
          this.selectedDocs = this.ddts.filter(item => item.shipmentStatus == null).map(item => item.id);
        } else {
          this.selectedDocs = [];
        }
      },
      getStatus(item) {
        return item.shipmentStatus ?? 0;
      },
      getStatusBackground(item) {
        let status = this.getStatus(item);
        let color = this.getShipmentStatusInfo(status).color;
        return "striped_" + color;
      },
      filterByStatus(status) {

        if(status === 0) this.shipmentsFilter.shipmentStatus = null;
        else this.shipmentsFilter.shipmentStatus = status;

        this.applyDdtsFilters();
      },
      applyDdtsFilters() {
        
        this.apiDocumentsFilteredPost().then((res) => {
          this.ddts.length = 0;
          this.ddts = res.map(el => ({...el,
            customertext: `${el.customerCode?.replace("C", "").replace("F", "").trim()} - ${el.customerBusinessName}`,
            recipienttext: `${el.recipientBusinessName}`,
            doctext: `${el.type}-${el.code}${el.bis.trim() === ""? "" : "/" + el.bis}`}));
        });

        this.apiShipmentsFilteredPost().then((res) => {
          this.shipments.length = 0;
          this.shipments = res?.map(this.mapShipmentProperties.bind(this));
        });
      },
      mapShipmentProperties(el) {

        let customertext = el?.documents?.map(doc => `${doc.customerCode.replace("C", "").replace("F", "").trim()} - ${doc.customerBusinessName}`)?.at(0);
        let recipienttext = el?.documents?.map(doc => `${doc.recipientBusinessName ?? doc.customerBusinessName}`)?.at(0);
        let docstext = el.documents?.map(doc => `${doc.type}-${doc.code}${doc.bis.trim() === ""? "" : "/" + doc.bis}`)?.join('; ');

        return { ...el, customertext, recipienttext, docstext };
      },
      clearDdtsFilters() {
        this.selectedDocs.length = 0;

        this.shipmentsFilter.shipmentStatus = null;

        this.shipmentsFilter.year = this.$moment().year();
        this.shipmentsFilter.code = null;
        this.shipmentsFilter.bis = "";
        this.shipmentsFilter.documentCategory = [];
        this.shipmentsFilter.docDate = `${this.$moment().add(-15, "days").format("DDMMYYYY")}..${this.$moment().add(2, "days").format("DDMMYYYY")}`;
        this.shipmentsFilter.shipmentDate = `${this.$moment().format("DDMMYYYY")}`;

        this.shipmentsFilter.customerCode = [];
        this.shipmentsFilter.customerSearchString = "";

        this.shipmentsFilter.recipientSearchString = "";

        this.applyDdtsFilters();
      },
      initialize() {
        this.initBulkCustomersCollection();
        this.selectedDocs.length = 0;
        //this.apiDocumentsGetMetodoDocTypesGet();
        this.apiDocumentsGetMetodoDocCategoriesGet();
        this.apiSendersGet();

        this.shipmentsFilter.year = this.$moment().year();
        this.shipmentsFilter.code = null;
        this.shipmentsFilter.bis = "";
        this.shipmentsFilter.documentCategory = [];
        this.shipmentsFilter.docDate = `${this.$moment().add(-15, "days").format("DDMMYYYY")}..${this.$moment().add(2, "days").format("DDMMYYYY")}`;
        this.shipmentsFilter.shipmentDate = `${this.$moment().format("DDMMYYYY")}`;
        this.shipmentsFilter.printed = null;

        this.applyDdtsFilters();
      },
      validateBeforePrinting() {

        if (this.selectedDocs.length === 0) {
          this.showError(this.$t('selectOne'));
          return false;
        }

        if(!this.validateSelectedRecipients()) {
          this.showError(this.$t('selectSameRecipient'));
          return false;
        }

        return true;
      },
      validateSelectedCustomers() {

        let documents = this.ddts.filter(doc => this.selectedDocs.includes(doc.id));
        let customerCode = documents.at(0).customerCode;

        return documents.every(selDoc => selDoc.customerCode === customerCode);
      },
      validateSelectedRecipients() {

        let documents = this.ddts.filter(doc => this.selectedDocs.includes(doc.id));
        //let addressCode = documents.at(0).addressCode;
        let businessName =  documents.at(0).recipientBusinessName;
        return documents.every(selDoc => /*selDoc.addressCode === addressCode &&*/ selDoc.recipientBusinessName === businessName);
      },
      getShipmentActionCollection: function (item) {
        switch(item.shipmentStatus) {
          case 1:
            return [
              {btnColor: "info", action: () => this.reprintShipment(item, ""), icon: "mdi-printer"},
              {btnColor: "secondary", action: () => this.reprintShipment(item, "T"), icon: require("@/assets/zebra.png")},
              {color: "secondary",action: () => this.modifyShipment(item), icon: "mdi-pencil"},
              {color: "secondary", action: () => this.confirmCancelShipment(item), icon: "mdi-close-circle"}
            ];
          case 2: case 4: case 5:
            return[
            {color: "secondary", action: () => this.confirmRemakeShipment(item), icon: "mdi-undo-variant"}
          ];
          case 3: return [
            {color: "secondary",action: () => this.modifyShipment(item), icon: "mdi-pencil"},
            {color: "secondary", action: () => this.confirmCancelShipment(item), icon: "mdi-close-circle"}
          ];
        }

        return [];
      },
      getDocumentActionCollection: function (item) {
        switch (item.shipmentStatus) {
          case 0:
          case null:
            return [
            {btnColor: "secondary",action: () => this.setShipmentStatus(item, 4), icon: "mdi-check-circle"},
            {btnColor: "info", action: () => this.setShipmentStatus(item, 5), icon: "mdi-truck"}
          ];
        }

        return [];
      },
      setShipmentStatus: function(item, status) {

        item.shipmentStatus = status;
        item.shipmentDate = this.$moment();

        //if(!this.validateBeforePrinting()) return;

        this.apiDocumentsWithPackagesPost([ item.id ])
            .then((response) => {

              response.shipmentStatus = status;
              response.shipmentDate =this.$moment();

              this.apiCreateShipmentWithStatusPost(response)
                  .then((res) => {
                    response.Id = res;
                    this.shipments.unshift(this.mapShipmentProperties(response));
                  });
            });

      },
      switchCustomerSearchType() {
        this.customerSearchType = this.customerSearchType === 'list' ? 'string' : 'list';
        if (this.customerSearchType === 'string') {
          this.shipmentsFilter.customerCode = [];
        } else if (this.customerSearchType === 'list') {
          this.shipmentsFilter.customerSearchString = null;
        }
      },
      downloadBordereau: function() {

        let dateStr = this.bordereauDate;
        let dateParts = dateStr.split("/");

        let payload = {shipmentDate:dateParts[2]+'-'+dateParts[1]+'-'+dateParts[0]};
        this.apiBordereauExport(payload)
            .then((res) => {
              this.downloadBordereauPdf(`bordereau_${this.bordereauDate.replace("/","")}`, res);
              this.dialog = false;
              this.clearedBordereauDate();
            });
      },
      downloadBordereauPdf: function (filename, content) {
        var binaryData = [];
        binaryData.push(content);
        const url = window.URL.createObjectURL(new Blob(binaryData));
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${filename}.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      },
      printShipment: function() {

        if(!this.validateBeforePrinting()) return;

        this.apiDocumentsWithPackagesPost(this.selectedDocs)
            .then((response) => {
              this.selectedDocs = [];
              this.shipment = response;
              this.$router.push({ name: 'Pages/TntShipment' });
            })
      },
      modifyShipment: function(item) {

        this.apiShipmentsIdGet(item.id)
            .then((response) => {
              this.selectedDocs = [];
              this.shipment = response;
              this.$router.push({ name: 'Pages/TntShipment' });
            })
      },
      confirmCancelShipment: function (item) {

        this.showConfirm({
          message: this.$t('cancelShipment'),
          action: this.cancelShipment,
          params: item
        });
      },
      confirmRemakeShipment: function (item) {
        this.showConfirm({
          message: this.$t('remakeShipment'),
          action: this.remakeShipment,
          params: item
        });
      },
      cancelShipment: function (item) {

        item.shipmentDate = moment(item.shipmentDate).format("YYYY-MM-DD")
        item.documents = item.documents.map(document => {
          document.documentDate = moment(document.documentDate).format("YYYY-MM-DD");
          return document;
        });

        item.shipmentAction = "D";

        this.apiShipmentsHandleShipmentWithLabelsPost(item)
          .then((res) => {

            this.showSuccess(this.$t('completed'));
            item.shipmentStatus = 2;

            let docIndexes = item.documents.map(x => x.id);

            this.ddts.forEach((d) => {
              if (docIndexes.includes(d.id)) {
                d.shipmentStatus = 2;
              }
            });
          });
      },
      remakeShipment: function(item) {
        this.apiShipmentsUndoShipment(item.id)
            .then((res) => {

              this.showSuccess(this.$t('completed'));

              let index = this.shipments.indexOf(item);
              if (index > -1) {
                this.shipments.splice(index, 1);

                let docIndexes = item.documents.map(x => x.id);

                this.ddts.forEach((d) => {
                  if (docIndexes.includes(d.id)) {
                    d.shipmentStatus = 0;
                  }
                });

                this.apiDocumentsWithPackagesPost(docIndexes)
                    .then((response) => {
                      this.selectedDocs = [];
                      this.shipment = response;
                      this.$router.push({ name: 'Pages/TntShipment' });
                    })
              }
            });
      },
      reprintShipment: function (item, mode) {

        item.shipmentDate = moment(item.shipmentDate).format("YYYY-MM-DD")
        item.documents = item.documents.map(document => {
          document.documentDate = moment(document.documentDate).format("YYYY-MM-DD");
          return document;
        });
        item.shipmentAction = "R";
        item.labelFormat = mode;

        this.apiShipmentsHandleShipmentWithLabelsPost(item)
            .then((res) => {

              console.log(item.documents.reduce((a, b) => { a.push(b.code); return a }, []));

              this.downloadPdf({
                filename: `${this.$moment().format('YYYYMMDD')}-${item.documents.reduce((a, b) => { a.push(b.code); return a; }, []).join('-')}`,
                content: res
              });
            });
      },
      getShipmentStatusInfo(shipmentStatus) {

        let statusInfo = {};

        switch (shipmentStatus) {
          case 1:
            statusInfo.label = this.$t('printed');
            statusInfo.color = "processed";
            statusInfo.icon = "mdi-printer";
            break;
          case 2:
            statusInfo.label = this.$t('canceled');
            statusInfo.color = "canceled";
            statusInfo.icon = "mdi-progress-close";
            break;
          case 3:
            statusInfo.label = this.$t('generatedWithErrors');
            statusInfo.color = "wip";
            statusInfo.icon = "mdi-progress-alert";
            break;
          case 4:
            statusInfo.label = this.$t('fulfilled');
            statusInfo.color = "fulfilled";
            statusInfo.icon = "mdi-check-circle";
            break;
          case 5:
            statusInfo.label = this.$t('fulfilledWithOther');
            statusInfo.color = "fulfilledWithOther";
            statusInfo.icon = "mdi-truck";
            break;
          default:
            statusInfo.label = "Unknown";
            statusInfo.color = "unknown";
            statusInfo.icon = "mdi-alert-octagon";
            break;
        }

        return statusInfo;
      },
      initBulkCustomersCollection: async ({ dispatch }, force) => {
        if (!!initializing.bulkCustomersCollection) return;

        if (state.customers.length == 0 || !!force) {
          initializing.bulkCustomersCollection = true;
          dispatch('apiCustomersBulkCustomersGet', force);
        }
      },
      ...call('customers', ['initBulkCustomersCollection']),
      ...call("app/*"),
      ...call("tnt/*"),
      ...call("utils/*"),
  },
};
</script>

<style>
.bold {
  font-weight: bold;
}
.roundChip {
  margin-left: -6px !important;
  margin-right: -5px !important;
}
tr:nth-child(even) {background: rgb(235, 235, 235)}
tr:nth-child(odd) {background: rgb(255, 255, 255)}
tr:hover{background: rgb(215, 215, 215) !important}

.striped_processed, .striped_processed:hover {
  background: rgba(48, 109, 70, 0.3) !important;
}

.striped_canceled, .striped_canceled:hover {
  background: rgba(140, 0, 30, 0.3) !important;
}

.striped_wip, .striped_wip:hover {
  background: rgba(229, 146, 0, 0.3) !important;
}

.striped_fulfilled, .striped_fulfilled:hover {
  background: rgba(1, 61, 75, 0.5) !important;
}

.striped_fulfilledWithOther, .striped_fulfilledWithOther:hover {
  background: rgba(0, 202, 227, 0.3) !important;
}

.v-chip-group .v-chip--active {color: black; font-weight: bold;}
</style>
